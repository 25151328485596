import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"

export const query = graphql`
  query CalendarPageQuery {
    file(name: { eq: "calendar-inner" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const CalendarPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <SEOComponent title="Calendar" description="Strong Foundations Calendar" />
      <Hero image={banner} header="Calendar" />
      <section className="container my-4 mx-auto pt-0 px-4 pb-6">
        <div className="overflow-hidden pb-96 relative h-0">
          <iframe
            src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23cc66cc&amp;src=e8gtj3t5h5b7p5d4q8mc1doo3g%40group.calendar.google.com&amp;color=%2323164E&amp;ctz=America%2FNew_York"
            title="calendar"
            className="absolute left-0 top-0 h-full w-full border-0"
            width="900"
            height="600"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </section>
    </Layout>
  )
}

export default CalendarPage
